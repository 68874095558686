import React from "react";

import { Typography } from "~/components/Typography";

import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	root: {
		backgroundColor: "#EBC501",
		padding: "4px 10px",
		borderRadius: 4,
	},
	message: {
		fontSize: 14,
	},
}));

export const PendingLabel: React.FC = () => {
	const styles = useStyles();
	return (
		<Box className={styles.root}>
			<Typography variant="body1" className={styles.message}>
				Pending
			</Typography>
		</Box>
	);
};
