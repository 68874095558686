import React, { useMemo } from "react";

import { ITableRow, Table } from "~/components/Table";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { idColumns } from "./columns";
import { FeedBackMessage } from "~/views/reviews/_lib/FeedBackMessage";

const useStyles = makeStyles(() => ({
	contentContainer: {
		display: "flex",
		flexFlow: "row nowrap",
		height: "100%",
		overflow: "hidden",
	},
	tableContainer: {
		display: "flex",
		flexFlow: "column nowrap",
		width: "100%",
		height: "100%",
		overflow: "auto",
	},
	table: {
		minHeight: 350,
		height: "100%",
		userSelect: "none",
	},
}));

export const PendingList = ({ data }: { data: any[]; children?: any }) => {
	const styles = useStyles();

	const rows: ITableRow[] = useMemo(
		() =>
			data?.map((node: any) => {
				return {
					id: `${node.id}`,
					values: [node.phoneNumber, node, node?.id],
				} as ITableRow;
			}) || [],
		[data],
	);

	const content = useMemo(() => {
		return (
			<Box className={styles.contentContainer}>
				<Box className={styles.tableContainer}>
					<Box className={styles.table}>
						<Table
							columns={idColumns}
							rows={rows}
							loading={false}
						/>
					</Box>
				</Box>
			</Box>
		);
	}, [rows, styles.contentContainer, styles.table, styles.tableContainer]);

	return (
		<Box
			display="flex"
			flexDirection="column"
			flexWrap="nowrap"
			height="100%"
		>
			{content}
		</Box>
	);
};
