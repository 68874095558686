import React from "react";

import { useAuth } from "@api/auth";

import { createReviewView, ViewComponent } from "../_lib/view";

import { ReviewProvider } from "~/views/reviews/_lib/context";

import { isProductEnabled } from "@utils/products";

import { ReviewPendingDisplay } from "./_lib/PendingDisplay";

import { Redirect } from "~/components/Redirect";
import { ProductType } from "@api/products";

const PendingView: ViewComponent = () => {
	const { selectedCompany } = useAuth();

	const isReviewToolEnabled = isProductEnabled(
		selectedCompany,
		ProductType.ReviewTool,
	);
	if (!isReviewToolEnabled) {
		return <Redirect to="/leads/" />;
	}

	return (
		<ReviewProvider>
			<ReviewPendingDisplay />
		</ReviewProvider>
	);
};

export default createReviewView(PendingView, {
	title: "Pending",
	internalPad: true,
	internalScroll: true,
});
